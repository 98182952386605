.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.backgroundParallelograms {
  width: 300px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 97px;
}

.backgroundParallelograms p {
  color: #ffffff;
  font-size: 30px;
}

.errorCode {
  position: relative;
  bottom: 80px;
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textContent h1, .textContent span {
  color: #12233c;
}

.textContent span {
  font-size: 18px
}

.textContent span:first-child {
  font-weight: 700;
}

.textContent > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.parallelogramDetailRed {
  width: 130px;
  height: 160px;
  transform: skew(25deg);
  position: relative;
  top: 200px;
  background: #d72e3f;
  margin-right: 20px;
  left: 30px;
}


.parallelogramDetailBlue {
  width: 230px;
  height: 300px;
  transform: skew(25deg);
  position: relative;
  top: 100px;
  background: #12233c;
  margin-right: 20px;
  z-index: -1;
}


@media (max-width: 900px) {
  .content {
    flex-direction: column;
    align-items: center;
  }
  
}
